.signInBtn {
  color: white;
  background: #c95e89;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 10px;
  font-weight: bold;
  &:hover {
    background: #ef8193;
  }
}
