.message {
  display: flex;
  align-items: center;

  .text {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    position: relative;
    color: white;
    font-size: larger;

    .sentTime {
      font-size: xx-small;
      opacity: 0.5;
    }
  }

  .userIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2px 5px;
  }
}

.sent {
  flex-direction: row-reverse;

  .text {
    color: white;
    background: #0b93f6;
    align-self: flex-end;
    text-align: right;
  }
}

.received {
  .text {
    background: #e5e5ea;
    color: black;
    text-align: left;
  }
}
