.chatRoomBox {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.chatRoomBox::-webkit-scrollbar {
  width: 0.25rem;
}

.chatRoomBox::-webkit-scrollbar-track {
  background: #1e1e24;
}

.chatRoomBox::-webkit-scrollbar-thumb {
  background: #ffd2c4;
}

.form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;

  .inputBar {
    line-height: 1.5;
    width: 100%;
    font-size: 1.5rem;
    background: #fefee7;
    color: black;
    outline: none;
    border: none;
    padding: 0 10px;
  }

  .submitBtn {
    width: 20%;
    background-color: #ef8193;
    font-size: x-large;
    &:hover:not([disabled]) {
      background-color: #c95e89;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  // .MuiTouchRipple-root {
  //   background-color: #c95e89;
  // }
}
